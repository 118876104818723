@import url(https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}

/* HEADER */
.dn{
  display:none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.footerwidgetcol p a{
  color:#FFF;
}
#output{color:#f00}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
body{
   width: 100% !important;
   padding:0px;
   margin:0px;
   font-family: 'Jost', sans-serif;
}
.container-fluid{
  padding:0px;
  margin:0px;
}
.layout{
  max-width: 1550px;
  margin:0px auto !important;
  padding: 0px 67px 0px 91px;
  text-align: center;
  color:#FFF;

}
.header-navigation{
  top: 0px;
  width: 100%;
  background-color:#000 !important;
  color:#fff;
  position: fixed;
  z-index:99;
}
.logo{
  margin: 25px 0px 30px;
}
.navbarmenu{
    padding-top:20px;
    text-align: center;
}

.navbarmenu > .nav-link{
  color:#FFF !important;
  font-family: 'Jost', sans-serif;
  font-size: 17px;
  font-weight: 600;
  margin: 0px 16px;

}

.pull-right{
  float:right;
}
.row-full{
  width: 100%;
  text-align: left;
  margin:0 auto;
}

.btn-mission{
  font-size: 16px !important;
  border: 2px solid #FFF !important;
  border-radius: 4px;
  padding:7px 20px !important;
  width: auto !important;
  font-weight: bolder;
  background: transparent !important;
}
.non-special:hover{
  text-decoration: underline;
}
.btn-mission:hover{
  background-color: #FFF !important;
  color:#000 !important;
  border: 2px solid #000 !important;
}
.pathbtnmob{
  background-color:#2E2E2E;
  padding:10px 20px 45px 25px;
}
.btnmob{
  width:auto;
  float:left;
  margin-bottom:10px;
  color:#FFF;
  
}
.btnmob a{
  color:#FFF;
  font-size: 14px !important;
  padding-left:60px;
  padding-right:60px;
  text-decoration: none;
}

.main_banner{
  background-image: url(/static/media/bg_web.55dd5f29.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
  
  padding:0px;
  margin:0px;
  margin-top:80px;
}
.titlebanner{
  color:#FFF;
  font-family: 'Jost', sans-serif;
  font-weight: bold;
  font-size: 65px;
  width: 50%;
  
}
.subtitlebanner{
  color:#FFF;
  font-family: 'Jost', sans-serif;
  margin-top:30px;
  font-size: 22px;
}
.bannercontent{
  padding: 140px 100px 0px 10px;
}
.btnbanner{
  background-color: #F00229;
  border:1px solid #F00229;
  font-family: 'Jost', sans-serif;
  font-size:18px;
  font-weight: bold;
  margin-top:15px;
  padding:10px 30px;
}
.btnbanner:hover{
  background-color: #BE0404;
}
.wwd{
  background-color:#070A11;
  
}
.circle{
  margin-top:110px;
  margin-bottom: 110px;
}
.wwd-content{
  padding-top:260px;
  padding-left:45px;
  font-family: 'Jost', sans-serif;
}
.wwd-content h2{
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
}
.wwd-content .logowwd{
  margin-top:10px;
  margin-bottom:20px;
}
.wwd-content p{
    font-size: 18px;
    font-weight: 400;
    width: 80%;
    font-family: 'Jost', sans-serif;
    line-height: 30px;
}
.puntorojo{
  color:#EA0E00;
  font-size: 80px;
  line-height: 0px;
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  background-color: #fff;
}
.accordion-button:focus {
  border: none !important;
}
button:focus:not(:focus-visible) {
  outline: none !important;
  border: none !important;
}
.accordion-collapse{
  padding-left: 30% !important;
    margin-right: 50px;
    margin-top: -65px;
    z-index: 99 !important;
    position: relative;
}
/* SECTION CAN CALL HOME*/
.homeplace{
  background-color: #FBFBFB !important;
  padding-bottom:94px;
}
.section3{
  padding: 0px 45px;
}
.section3 h2{
  color: #F00229;
  text-align: center;
  margin-top:100px;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: -1px;
}
.section3 h3{
  color: #666;
  text-align: center;
  font-size:20px;
  font-weight: 600;
  margin-top:15px;
}

.section3 p{
  color: #666;
  text-align: left;
  font-size:20px;
  margin-top:50px;
  line-height: 30px;

}

.inconsection{
  color:#2e2e2e !important;
  margin-top:60px;
  padding-left:20px;
  padding-right: 20px;
}
.espaciadolateral{
  padding-left: 45px;
  padding-right: 45px;
}
.elemento_texto{
  font-size: 15px;
  font-weight: bold;
  height: 85px;
  line-height: 20px;
  padding-left:5px;
  vertical-align: middle !important;
}
.alp1{
  padding-top:10px;
}
.alp2{
  padding-top:20px;
}
.alp3{
  padding-top:30px;
}
.alp4{
  padding-top:40px;
}
.alp5{
  padding-top:50px;
}
.section4{
  color:#666 !important;
}

/* CONTRATACIÓN */
.contratacion{
  background-image: url(/static/media/img.e168e365.jpg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position-y: -40px;
}
.cuadrado{
  width: 30px;
  height: 30px;
  background-color: #F00229;
  position:relative;
  bottom:0px;
  left:0px;
  margin-top: -24px;
}
.hiring{
  height: 800px;
  background-position:-2vh; 
  padding: 350px 85px 0 40px;
  margin:0px;
}
.hiring h2{
  color:#FFF;
  font-size: 30px;
  font-weight: bold;
  margin-bottom:30px;
}
.hiring p{
  color:#FFF;
  font-size:18px;
  line-height: 22px;
}
.sectionaccordion{
  padding:135px 30px;
}
.acheader .accordion-button{
  font-size: 20px !important;
  color:#2E2E2E !important;
  font-weight: bold;
}
.accordion-item {
  background-color: #fff;
  border:none;
  border-bottom: 1px solid rgba(0,0,0,.125);
  padding: 15px 0px;
}

.accordion-button:focus{
    box-shadow:none;
  }
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  background: url(/static/media/PLUS.ea03b026.svg);
  background-repeat: no-repeat;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  opacity: 1;
}
.Btnformmail .blackrowc2a{
  background-color: #FFF;
  background: url(/static/media/sola-02.6e8560fc.svg);
  background-size: cover;

  height: 40px;
  background-position: -6px 0px;
  background-size: cover;
  width: 15px;
  float:right;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-left:20px;
  margin-top:-8px;
}
.Btnformmail:hover .blackrowc2a{
  background: url(/static/media/sola-01.2d85774d.svg);
  background-size: cover;

  height: 40px;
  background-position: -6px 0px;
  background-size: cover;
  width: 15px;
  float:right;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: #000;
  margin-left:20px;
  margin-top:-8px;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .accordion-button::after {
    -webkit-mask: url(/static/media/PLUS.ea03b026.svg);
    mask: url(/static/media/PLUS.ea03b026.svg);
    -webkit-mask-size: cover;
            mask-size: cover;
    background: #000;
  }
}
.accordion-button:hover::after {
  background: url(/static/media/PLUS-hover.3737fdc2.svg);
  background-repeat: no-repeat;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  opacity: 1;
}
.accordion-button:not(.collapsed)::after {
    background: url(/static/media/LESS.750e1b09.svg);
    -webkit-mask: url(/static/media/LESS.750e1b09.svg);
    mask: url(/static/media/LESS.750e1b09.svg);
    -webkit-transform: rotate(-180deg );
            transform: rotate(-180deg );
}
.section5{
  padding-left: 45px;
  padding-right: 45px;
}
.jobs{
  background-color: #FBFBFB !important;
  padding-bottom:50px;
}
.jobs h3{
  color: #000;
  text-align: center;
  margin-top:90px;
  font-weight: 700;
  font-size: 40px;
}
.jobs h2{
  color: #000;
  text-align: left;
  margin-top:20px;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}


/* CONTACT FORM*/

.contactsection{
  background-color: #FFF; 
  margin:0px;
  background-image: url(/static/media/fondo_logo.9793f1cd.png);
  background-repeat: no-repeat;
  background-size: 38%;
  background-position-y: bottom;
  height: 515px;
}
.text-danger-personalized{
  color: #F00229;
}
.contactform{
    padding:100px 50px;
}
.n-l-b{
  border-left:none;
} 
.n-r-b{
  border-right:none;
}
.n-b-b{
  border-bottom: none !important;
}
.contactform table tr td{
  padding-top: 10px;
  padding-bottom: 10px;
}
.labelform{
  font-weight: bold;
  font-size: 14px;
  padding-top:10px;
}
.formfield{
  font-weight: 300;
  border:none;
}
.smallw{
  width:150px !important;
}
.contact-content{
  padding-left:45px;
  padding-right:45px;
}
.contact-content h2{
  color:#000;
  font-weight: bold;
  padding:125px 0px 0px 0px ;
  font-size: 40px;
}
.contact-content p{
  color:#000;
  padding:0px 0px 8px 0px ;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}
.contact-content p .text-danger{
  color: #F00229 !important;
}
.formfield{
  background-color: transparent;
}
.Btnformmail{
  padding: 10px 30px 5px 30px;
  color: #000;
  background: #fff;
  font-weight: bold;
  border:1px solid #000;
  border-radius: 4px;
  font-size: 20px;

}
.Btnformmail::after, .Btnformmail:hover{
  background: #000;
  color: #fff;
  border:1px solid #000;
}


/* FOOTER  */
.text-right{
  text-align: right !important;
}
.footerwidget{
    background-color:#000;
    padding-top: 80px;
    padding-bottom: 100px;
}
.footerwidgetcol  p{
  font-size:13px;
  line-height: 30px;
}
.rowclass{
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 20px;
  margin-left:15px;

}
.btntop{
  -webkit-transform: rotate(270deg) !important;
          transform: rotate(270deg) !important;
  color:#FFF;
  margin-top:25px;
  float: right;;
}
.blackrow{
  color:#000;
}

.btntop a{
  color:#FFF;
  font-size: 12px;
}
/*
.accordion-button:not(.collapsed)::after {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    transform: rotate(
-180deg
);
}
.accordion-button:not(.collapsed)::after {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    transform: rotate(
-180deg
);
}
.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}
*/
@media (max-width: 767px) {
  body{
    width: auto !important;
  
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
  .layout{
    max-width: 100%;
    margin:0px auto !important;
    padding:0px 20px;
    text-align: center;
    color:#FFF;
  
  }
  .al55{
    height: 55px !important;
  }
  .logo{
    margin: 10px 0px 10px;
  }
  .row-full-top{
    height: 60px !important;
  }

  .main_banner{
    background-image: url(/static/media/bg_mob.6430778c.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position:0vh; 
    background-position-y: top;
    padding:0px;
    margin:0px;
    height: unset;
    padding-bottom:60px;
    margin-top:0px;
  }
  .header-navigation{
    position:unset;
  }
  
  .titlebanner{
    color:#FFF;
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    font-size: 55px;
    width: 92%;
    line-height: 62px;
    padding-right: 30px;
    margin-left:10px;
    
  }
  .subtitlebanner{
    color:#FFF;
    font-family: 'Jost', sans-serif;
    margin-top:30px;
    font-size: 20px;
    font-weight: 300;
    padding-left:10px;
    width:100%;
  }
  .bannercontent{
    padding: 200px 10px 0px 10px;
  }
.puntorojo{
  line-height: 0px;
}
  .btnbanner{
    margin-top:40px;
    margin-left:10px;
    padding-left: 50px;
    padding-right: 50px;
  }

  /*WHAT WE DO CONTENT*/
  .wwd{
    margin-top:-10px;
    height: unset;
  }
  .wwd-content{
    padding:0px;
    padding: 40px 20px 20px !important; 
    text-align: left;
  }
  .wwd-content h2{
    text-align: left;
    font-size: 20px;
  }
  .wwd-content p{
    text-align: left;
    font-weight: 300;
    width:100%;
    margin-top:10px;
  }
  .circle{
    width:100%;
    margin-top:0px;
    margin-bottom:60px;
  }
  /* SECTION PLACE YOU CAN CALL HOME*/
  .section3{
    padding: 0px 20px;
  }
  .section3 h2, .section3 h3{
      padding: 0px;
      text-align: left;
  }
  .section3 h2{
    font-size: 46px;
  }
  .section3 h3{
    line-height: 30px;
  }
  .hrline{
    border-top: 1px solid #F00229;
    margin-top:30px;
    width: 40px;
    opacity: 1;
  }
  .section3 p{
    margin-top:30px;
    text-align: left;
    font-weight: 400;
    padding:0;
    margin-bottom:50px;
    font-size: 18px;
    line-height: 30px;
  }
  .elemento_texto{
    color: #000;
    text-align: left;
    padding-left:10px
  }
  .carousel-indicators{
    bottom:-60px !important;
  }
  .carousel-indicators button.active{
    background: #F00229 !important;
  }
  .carousel-indicators button{
    background: #2E2E2E !important;
    border-radius: 15px !important;
    width: 12px !important;
    height: 12px !important;
    border:none !important;
  }
  img.d-inline-block-homeplace{
    max-width: 80px !important;
    max-height: 80px !important;
    width: 80px;
  }
  .carousel-control-next, .carousel-control-prev{
    background-color: #F00229;
    border-radius: 10px;
    margin-top:30px;
    opacity:1;
    height: 20px;
    width: 20px;
    padding:5px;
    display:none !important;
  }
  .carousel-control-next span, .carousel-control-prev span{
      font-size: 10px;
  }
  .carousel-inner {
      padding-left: 10px;
      padding-right: 10px;
  }

  /*CONTRATACION*/
  .contratacion{

    background-image: url(/static/media/img.e168e365.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: black;
  }
  .accordionrow{
    background-color:#fff;
  }
  .layout-hire{
    max-width: 100%;
    margin:0px auto !important;
    padding:0px 0px;
    text-align: center;
    color:#FFF;
  
  }
  .row-full-hire{
    width: 100%;
    text-align: left;
    margin:0 auto;
  }
  .hiring{
    padding: 180px 40px 20px;
    font-weight: 300;
    height: unset;
  }
  .hiring h2{
    font-size: 40px;
  }
  .hiring p{
    line-height: 30px;
  }
  .cuadrado{
    width: 30px;
    height: 30px;
    background-color: #F00229;
    position:relative;
    bottom:-20px;
    left:-40px;
  }
  .sectionaccordion{
    padding: 30px;
  }
  .accordion-item {
      padding: 5px 0px;
  }
  .accordion-collapse{
    padding-left: 0px !important;
      margin-right: 0px;
      margin-top: 0px;
      z-index: unset;
      position: unset;
  }
  .accordion-body{
    text-align: justify;
  }
/* JOBS */
  .jobs h2 {
      color: #000;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 40px;
      font-size: 20px;
      font-weight: 300;
      padding: 0px 0px;
      line-height: 30px;
  }
  .jobs h2{
    padding:0px 20px;
  }
  .section5{
    padding-left: 0px;
    padding-right: 0px;
  }
  /* CONTACT SESSION */
  .contact-content{
      padding-left:0px;
      padding-right: 0px;
  }
  .contact-content h2{
      padding: 40px 20px 20px;
      text-align: left;
      font-size: 32px;
  }
  .contact-content p{
      padding: 0px 20px;
      text-align: left;
      font-size: 18px;
      line-height: 30px;
  }
  .contactform{
    padding: 40px 20px;
  }
  .contactsection{
    height: unset;
    background-size: 100%;
  }
  .labelform{
    display:block;
    text-align: left;

  }
/* FOOTER WIDGET */
  .footerwidget{
      background-color:#000;
      padding: 50px 10px ;
      padding-bottom: 40px;
  }
  .footerwidgetcol  p{
    font-size:15px;
    line-height: 30px;
    text-align: left;
  }
  .col-xs-10{
    width: 80% !important;
    float:left;
    text-align: left;
  }
  .col-xs-2{
    width: 20% !important;
    float:left;
  }
  .col-xs-6{
    width: 50% !important;
    float:left;
    text-align: left;
  }
  .hrfooter{
    background-color: #F00229;   
    width:90%;
    margin-top:130px;
    margin-bottom: 40px;
    opacity: 1;
    margin-left:10px;
    margin-right: 30px;

  }
  .btntop{
    width: 100px !important;
    margin-top:10px;
    float:unset
  }
}

/*$Id$*/
@CHARSET "UTF-8";

.embed_jobs_head {
	background-color: #fbfbfb;
	padding: 50px;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}
.rec_job_listing_div_jobs ul,.rec_job_listing_div_jobs li{
	margin:0;
	padding:0
}

.embed_jobs_head2 {
	background-color: #fff;
	margin: auto;
	font: normal 14px lucida grande, lucida sans, lucida sans unicode, arial,helvetica, verdana, sans-serif;
	border: 1px solid #eee;
	padding: 30px;
	width: 100%;
	overflow: hidden;
}
.rec_facet_group{
	width: 40%;
    margin-right: 24px;
    border: 1px solid #dee4f0;
    padding: 0px;
    border-radius: 4px;
    height: 100%;
    background-color: #FAFAFA !important;
}
.rec_job_listing_div_jobs{
	flex: 1 1 100%;
}

.embed_jobs_head3 {
	color: #767676;
	line-height: 27px;
}

.rec_job_listing_div_jobs .rec-group .rec-job-title {
	font-size: 1.2em;
	list-style: none;
}

.rec_facet_group .cw-rec-filter {
	overflow: scroll;
	width: 100%;
	padding: 0 0 8px;
}
.rec_facet_group > div{
	border-bottom: 1px solid #dee4f0;
}
.rec_facet_group > div:last-child{
	border-bottom:none
}

.rec_facet_group .cw-rec-filter .rec_ul_filter {
	list-style-type: none;
}

.rec_ul_filter .cw-facet-checkbox {
	float: left;
	margin-right: 10px;
	margin-top: 9px;
}

.rec_ul_filter .cw-facet-checkbox-label {
	display: block
}
#rec_job_listing_div{
	display: flex;
}
 .embed_jobs_with_style .embed_jobs_head3{
    line-height: 27px;
    font-size: 0.875rem;
    color: #0e1b26;
     font-weight: bold;
}
 .embed_jobs_with_style .cw-facet-checkbox-label span{
 	color: #747789;
 	font-weight: normal;
}
 .embed_jobs_with_style ul,.embed_jobs_with_style li{
 	padding:0;
 	margin: 0;
 	list-style: none;
 }
  .embed_jobs_with_style li{
 	margin-bottom:8px
 }
 .embed_jobs_with_style .rec_facet_group .cw-rec-filter{
    padding:0;
    box-sizing: border-box;
    margin-top: 16px;
    background-color: #FFF;
    border-right: none;
}
.rec_facet_group div span{
  background-color: #FFF;
  width:94%;
  display: block;
  height: 30px;
}
.rec_facet_group div {
  padding-left:10px;
}
.embed_jobs_with_style li:before{
	content:'\2022';
	font-size:16px;
	position: absolute;
	left: 0;
	color:#111;
	top: -1px;
	visibility: hidden;
}
.embed_jobs_with_style .rec_ul_filter li:before{
	display:none;
}
.embed_jobs_with_style .rec-group li{
	padding: 0 0 0 0;
	position: relative;
	margin: 0 0 2px 0;
	line-height: 20px;
	display: inline-block;
	color: #191b22;
	font-weight: normal;
	width: 47%;
	box-sizing: border-box;
	float: left;
	color: #4a4a4a;
  font-family: 'Jost', sans-serif;
}
.embed_jobs_with_style .rec-group li span:first-child{
	padding-right: 8px;
	/* width:50%; */
	display:inline-block;
	color: #848484;
}
.embed_jobs_with_style  li.rec-job-title:before,.embed_jobs_with_style  li.zrsite_Job_Description:before{
	display:none
}
.embed_jobs_with_style .rec-group li.rec-job-title,.embed_jobs_with_style  li.zrsite_Job_Description{
	margin:0 ;
	padding:0
}
.embed_jobs_with_style  li.zrsite_Job_Description{
	margin-top:16px;
	line-height:20px;
	color: #50566d;
}
.embed_jobs_with_style .rec-group li.rec-job-title{
	margin-bottom: 8px;
/* 	display: block; */
	width: 100%;
	float: none;
}
.embed_jobs_with_style  li.zrsite_Job_Description{
	margin-top: 8px;
	width: 100%;
	float: none;
}
.embed_jobs_with_style .rec-job-info a{
	color: #EA0000;
	text-decoration: none;
	font-weight: bold;
}
.embed_jobs_with_style .rec-job-info a:hover{
	text-decoration: underline;

}
.embed_jobs_with_style ul.rec-job-info{
	margin-bottom: 16px;
	border-bottom: 1px solid #f2f4f9;
	padding-bottom: 16px;
}
.embed_jobs_with_style .cw-facet-checkbox {
  position: absolute;
  opacity: 0;
}
.embed_jobs_with_style .cw-facet-checkbox + span {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 16px;
  padding-left: 24px;
  font-size: 15px;
  font-family: sans-serif;
  color: #666;
}
.embed_jobs_with_style .cw-facet-checkbox + span:before {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background: transparent;
  width: 14px;
  height: 14px;
  box-shadow: inset 0 0 0 1px #474f62;
  border-radius: 2px;
  transition: 200ms ease-in-out all;
}
.embed_jobs_with_style .cw-facet-checkbox + span:hover:before{
	box-shadow: inset 0 0 0 1px #EA0000;
}
.embed_jobs_with_style .cw-facet-checkbox + span:after {
  position: absolute;
  display: block;
  top: 2px;
  left: 5px;
  content: "";
  width: 3px;
  height: 7px;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: 200ms ease-in-out all;
}
.embed_jobs_with_style .cw-facet-checkbox + span:hover {
  color: #333;
}
.embed_jobs_with_style .cw-facet-checkbox + label:focus {
  outline: none;
}
.embed_jobs_with_style .cw-facet-checkbox:checked + span:before {
  background: #EA0000;
  box-shadow: none;
  /* box-shadow: inset 0 0 0 1px #474f62; */
}
.embed_jobs_with_style .cw-facet-checkbox:checked + span:after {
  border-color: #fff;
}
.embed_jobs_with_style .cw-facet-checkbox:disabled + span:before {
  background: #f2f2f2;
  box-shadow: none;
}
.embed_jobs_with_style .cw-facet-checkbox:disabled + span:after {
  border-color: transparent;
}
.embed_jobs_with_style .cw-facet-checkbox:checked:disabled + span:before {
  background: #f2f2f2;
  box-shadow: none;
}
.embed_jobs_with_style .cw-facet-checkbox:checked:disabled + span:after {
  border-color: #cecece;
}

.embed_jobs_with_style_1.embed_jobs_with_style ul.rec-group{
	display: grid;
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
    grid-gap: 16px;
}
.embed_jobs_with_style_1  ul.rec-job-info{
	overflow: hidden;
    border: 1px solid #e5e9f3;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 0;
}
.embed_jobs_with_style_1  ul.rec-job-info:nth-child(even){
	 margin-right: 0;
}
.embed_jobs_with_style_1 #rec_job_listing_div{
	display: block;
}
.embed_jobs_with_style_1 .rec-group li{
	width: 100%;
}
.embed_jobs_with_style_1 .rec-group li span:first-child{
	width: 30%;
}
.embed_jobs_with_style_1 ul.rec-job-info:hover{
	background: #f5f6fa;
}
.embed_jobs_with_style_2 .rec_job_listing_div_jobs{
	width:100%;
	float: none
}
.embed_jobs_with_style_3 .rec_job_listing_div{
	display: flex;
}
.embed_jobs_with_style_1 .rec_facet_group,.embed_jobs_with_style_2 .rec_facet_group{
	width: auto;
    margin-right: 0;
    border: none;
    position: absolute;
    right: 0;
    top: -60px;
    height: auto;
    padding: 8px 0;
}
.embed_jobs_with_style_1 #rec_job_listing_div,.embed_jobs_with_style_2 #rec_job_listing_div {
    display: inherit;
}
.embed_jobs_with_style_1  .embed_jobs_head2,.embed_jobs_with_style_2  .embed_jobs_head2{
	position: relative;
	overflow: inherit;
}



.cw-easyapply-loading {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 10;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-top: -28px;
}
.cw-easyapply-loading div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 8px;
  background: #5588d9;
  -webkit-animation: cw-easyapply-loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          animation: cw-easyapply-loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  top: 0;
}
.cw-easyapply-loading div:nth-child(1) {
  left: 8px;
  -webkit-animation-delay: -0.24s;
          animation-delay: -0.24s;
}
.cw-easyapply-loading div:nth-child(2) {
  left: 24px;
  -webkit-animation-delay: -0.12s;
          animation-delay: -0.12s;
}
.cw-easyapply-loading div:nth-child(3) {
  left: 40px;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
.rec_job_listing_div_jobs li{
 	margin-right:24px
 }
.rec_job_listing_div_jobs li.zrsite_Job_Description span{
	word-break: break-all;
}

@-webkit-keyframes cw-easyapply-loading {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

@keyframes cw-easyapply-loading {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

@media (max-width: 624px){
	.embed_jobs_with_style{
		padding: 0px !important;
	}
	.embed_jobs_head2{
		width: 100% !important;
    background-color:#FBFBFB !important;
    border:none;
	}
  .rec_facet_group{
    display:none;
  }
  .embed_jobs_head2{
    padding:0px;
  }
  .zrsite_Industry, .zrsite_Work_Experience, .zrsite_Date_Opened, .zrsite_State,  .zrsite_Job_Description{
    display:none !important;
  }
  .rec-job-info{
    border: 1px solid #cecece !important;
    border-radius: 10px;
    margin: 10px 10px !important;
    max-height: auto;
    height: unset;
    padding: 15px 30px !important;
    display: inline-block;
    background-color: #FFF !important;
  }
  .embed_jobs_with_style{
    margin-left:50px;
    margin-right: 50px;
  }
  .rec-job-info li{
    text-align: left;
    font-size: 12px;
    width: 100% !important;

  }
  
}
